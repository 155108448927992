export const setRemainingTime = 'setRemainingTime'
export const setGameLink = 'setGameLink'
export const setGameId = 'setGameId'
export const setGame = 'setGame'
export const setBoardOrientation = 'setBoardOrientation'
export const setPieceSelection = 'setPieceSelection'
export const setRequestSender = 'setRequestSender'
export const setOpponentDetails = 'setOpponentDetails'
export const setTurn = 'setTurn'
export const setGameHistory = 'setGameHistory'
export const setPosition = 'setPosition'
export const setGameAnalyzer = 'setGameAnalyzer'
export const setPlayingGame = 'setPlayingGame';
export const setGameResult = 'setGameResult';
export const setAddPromoted = 'setAddPromoted'
export const setDeletePromoted = 'setDeletePromoted'
export const setClearPromoted = 'setClearPromoted'
export const setAtBeginning = 'setAtBeginning'
export const setModalOpen = 'setModalOpen'
export const setSelfTime = 'setSelfTime'
export const setOpponentTime = 'setOpponentTime'
export const setBoardTheme = 'setBoardTheme'
export const setNewMove = 'setNewMove'