import React from 'react'
import Search from './Search/Search'

const SearchPanel = () => {
    return (
        <div className='user-friend py-3 w-full'>
            <Search />
        </div>
    )
}

export default SearchPanel
