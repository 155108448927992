export const devArray = [
    {
        name: "Puspendu Bera",
        role: "Frontend Developer",
        skills: [
            { skillName: 'UI/UX', website: 'https://grow.google/intl/en_ca/certificates/ux-design/' }, 
            { skillName: 'ReactJS', website: 'https://react.dev' },
            { skillName: 'ReduxJS', website: 'https://redux.js.org' },
            { skillName: 'Vanilla JS', website: 'http://vanilla-js.com' }
        ],
        profiles: {
            github: 'https://github.com/PuspenduBera2003',
            facebook: 'https://www.facebook.com/itspuspendu9584/',
            linkedin: 'https://www.linkedin.com/in/puspendu-bera-29b78620a/'
        },
        image: 'https://firebasestorage.googleapis.com/v0/b/chess-14b8c.appspot.com/o/developers%2FPuspendu%20Bera.jpg?alt=media&token=e7f4902a-7223-4a9a-b1bd-1aa83f90fa91'
    },
    {
        name: "Santanu Kapas",
        role: "Backend Developer",
        skills: [
            { skillName: 'NodeJS', website: 'https://nodejs.org/en' },
            { skillName: 'PostgreSQL', website: 'https://www.postgresql.org/' },
            { skillName: 'WebSocket', website: 'https://socket.io/' },
            { skillName: 'RestAPI', website: '/#'}
        ],
        profiles: {
            github: 'https://github.com/Santanu-Kapas',
            facebook: '',
            linkedin: 'https://www.linkedin.com/in/santanu-kapas-3404732a4/'
        },
        image: 'https://firebasestorage.googleapis.com/v0/b/chess-14b8c.appspot.com/o/developers%2FSantanu%20Kapas.jpg?alt=media&token=163ca6d2-2b99-4b8d-8001-682218341e67'
    }
]