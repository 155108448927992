export const setAuthenticated = "setAuthenticated"
export const setSignUpInitialized = "setSignUpInitialized"
export const setShowBottomToast = "setShowBottomToast"
export const setUserDetails = "setUserDetails"
export const setUserFriend =  "setUserFriend"
export const setShowNotification = "setShowNotification" 
export const setUserRating = "setUserRating"
export const setHistoryBoard = 'setHistoryBoard'
export const setMoveHistory = 'setMoveHistory'
export const setHistoryGame = 'setHistoryGame'
export const setHistoryBoardOrientation = 'setHistoryBoardOrientation'
export const setOpponentDetails = 'setOpponentDetails'
export const setHistoryAnalyzer = 'setHistoryAnalyzer'
export const setConclusion = 'setConclusion'