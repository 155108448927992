export const setGame = 'setGame';
export const setRightClickedSquares = 'setRightClickedSquares'
export const setOptionSquares = 'setOptionSquares'
export const setMoveFrom = 'setMoveFrom'
export const setMoveTo = 'setMoveTo'
export const setPlayer = 'setPlayer'
export const setShowPromotionDialog = 'setShowPromotionDialog'
export const setMoveSquare = 'setMoveSquare'
export const setGameEnded = 'setGameEnded'
export const setStartTime = 'setStartTime'
export const setRotation = 'setRotation'
export const setOrientation = 'setOrientation'